import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';


export class Framework {
	constructor(){
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
	}
	
	checkURL(){
		this.changeContent();
	}
		
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		common.init();
		/*
		let imgArry = [
			"/assets/images/a.jpg",
			"/assets/images/b.jpg"
		];
		preload.startLoad(imgArry).then(res => {
		});
		*/
		function addN(n){
			let num = n + 1;
			return new Promise( resolve => {
				setTimeout(function(){
					resolve(num)
				}, 1000);
			});
		}
		async function getNum(){
			let result = await addN(199);
			return result;
		}
		getNum().then( (res) => {
			console.log(res)
		});
		common.resize();
		common.nav();
	}
	
}